
.button {
    display: inline-block;
    text-decoration: none;
    font-size: 13px;
    line-height: 2.15384615;
    min-height: 30px;
    margin: 0;
    padding: 0 10px;
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
    -webkit-appearance: none;
    border-radius: 3px;
    white-space: nowrap;
    box-sizing: border-box;

    color: #2271b1;
    border-color: #2271b1;
    background: #f6f7f7;
    vertical-align: top;
}

.button-primary {
    background: #2271b1;
    border-color: #2271b1;
    color: #fff;
    text-decoration: none;
    text-shadow: none;
}

.button-hero {
    font-size: 14px;
    min-height: 46px;
}
