.hero-box {
    position: relative;
    overflow: auto;
    margin: 16px 0;
    padding: 23px 10px;
    border: 1px solid #c3c4c7;
    box-shadow: 0 1px 1px rgb(0 0 0 / 4%);
    background: #fff;
    font-size: 13px;
    line-height: 1.7;
}

.hero-box-content {
    margin-left: 13px;
    max-width: 1500px;
}

.hero-box h2 {
    margin: 0;
    font-size: 21px;
    font-weight: 400;
    line-height: 1.2;
}
.hero-box h3 {
    color: #1d2327;
    margin: 1.33em 0 0;
    font-size: 16px;
}

.hero-box .about-description {
    font-size: 16px;
    margin: 0;
}

.hero-box-column-container {
    clear: both;
    position: relative;
}

.hero-box-column {
    width: 32%;
    min-width: 200px;
    float: left;
}
.hero-box-column:first-child {
    width: 36%;
}

.hero-box .button-hero {
    margin: 15px 13px 3px 0;
    padding: 12px 36px;
    height: auto;
    line-height: 1.4285714;
    white-space: normal;
}

.hero-box ul {
    list-style: none;
    padding: 0;
    margin: 0.8em 1em 1em 0;
}
.hero-box ul li {
    line-height: 1.13285714;
    list-style-type: none;
    padding: 0 0 8px;
    font-size: 14px;
}
