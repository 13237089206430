.notice {
    padding: 1px 38px 1px 12px;
    position: relative;
    margin: 5px 0 15px;
    background: #fff;
    border: 1px solid #c3c4c7;
    border-left-width: 4px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 4%);
}

.notice p {
    margin: 0.5rem 0;
    padding: 2px;
    font-size: 13px;
    line-height: 1.5;
}

.notice.notice-success {
    border-left-color: #00a32a;
}

.notice.notice-error {
    border-left-color: #d63638;
}

.notice.notice-warning {
    border-left-color: #dba617;
}
