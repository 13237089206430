#code-screen {
    display: grid;
    grid-template-columns: 45% 45%;
    column-gap: 5%;
}

#code-screen #terminal {
    display: block;
    background-color: #000;
    color: #1ed560;
    width: 100%;
    height: 100%;
    max-height: 600px;
    min-height: 200px;
}

.lines-of-code {
    display: block;
    text-align: center;
    font-size: 20px;
}
.actions {
    text-align: center;
}
